import { useMemo, useState } from 'react';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Card, Cell, CellText, fromTree } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { findIndustryFromTreeModelData } from 'src/models/applicant/resume/blocks/experience/editor/lib';
import { selectIndustriesTree } from 'src/models/applicant/resume/blocks/experience/editor/selectors';
import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import { ExperienceFieldProps } from 'src/pages/ResumeView/redesign/blocks/experience/Editor/types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import IndustryTreeSelector from 'src/pages/ResumeView/redesign/blocks/experience/Editor/fields/Industry/IndustryTreeSelector';

const MAX_SELECTED_COUNT = 5;

const TrlKeys = {
    label: 'resume.editor.experience.industriesTree.label',
    moreIndustries: 'resume.editor.experience.industriesTree.more',
};

const IndustryField = translation<ExperienceFieldProps & { disabled: boolean }>(
    ({ trls, fieldName, index, disabled, errorsMap }) => {
        const industriesTree = useSelector(selectIndustriesTree);
        const collection = useMemo(() => fromTree(industriesTree?.items || []), [industriesTree]);

        const { value, condition, onChange } = useResumeEditorListField<number[]>(
            fieldName,
            index,
            'companyIndustries',
            errorsMap
        );
        const [visible, setVisible] = useState(false);

        const handleChangeVisible = (isVisible: boolean) => setVisible(isVisible);

        const handleChanged = (selectedIds: string[]) => {
            if (!collection?.getTopLevel().length) {
                return;
            }

            const selectedIndustries = collection.getExistModels(selectedIds).map(({ id }) => Number(id));

            onChange(selectedIndustries);
        };

        const maxSelected = Number(condition?.maxcount ?? MAX_SELECTED_COUNT);

        const cardText = useMemo(() => {
            if (!industriesTree || isEmpty(industriesTree) || isEmpty(value)) {
                return trls[TrlKeys.label];
            }
            const industry = findIndustryFromTreeModelData(industriesTree.items, value[0].toString());

            if (industry) {
                return value.length - 1 >= 1 ? (
                    <>
                        {industry.text}
                        {NON_BREAKING_SPACE}
                        {trls[TrlKeys.moreIndustries]}
                        {NON_BREAKING_SPACE}
                        {value.length - 1}
                    </>
                ) : (
                    industry.text
                );
            }

            return trls[TrlKeys.label];
        }, [trls, industriesTree, value]);

        return (
            <>
                <Card
                    disabled={disabled}
                    padding={16}
                    borderRadius={16}
                    borderWidth="default"
                    stretched
                    onClick={() => handleChangeVisible(true)}
                >
                    <Cell right={<ChevronRightOutlinedSize24 />}>
                        <CellText maxLines={1} style="secondary">
                            {cardText}
                        </CellText>
                    </Cell>
                </Card>
                <IndustryTreeSelector
                    visible={visible}
                    value={value}
                    maxSelected={maxSelected}
                    collection={collection}
                    onChange={handleChanged}
                    onChangeVisible={handleChangeVisible}
                />
            </>
        );
    }
);

export default IndustryField;
